import React, { useState, useEffect } from 'react'
import { cva } from 'class-variance-authority'
import { Spinner } from 'components/Spinner'
import { PROMISE_STATES } from 'utils/constants'

import { ReactComponent as GoogleIcon } from 'i/google.svg'
import { ReactComponent as AppleIcon } from 'i/apple.svg'
import { ReactComponent as CheckIcon } from 'i/icons/checkmark.svg'

import styles from './Button.module.scss'

const buttonStyles = cva(styles.button, {
  variants: {
    variant: {
      primary: styles.buttonPrimary,
      secondary: styles.buttonSecondary,
      tertiary: styles.buttonTertiary,
      quaternary: styles.buttonQuaternary,
      quinary: styles.buttonQuinary,
      default: styles.buttonDefault,
      dark: styles.buttonDark,
      light: styles.buttonLight,
      success: styles.buttonSuccess,
      success2: styles.buttonSuccess2,
      outlined: styles.buttonOutlined,
    },
    size: {
      sm: styles.buttonSmSize,
      md: styles.buttonMdSize,
      md2: styles.buttonMd2Size,
      lg: styles.buttonLgSize,
      base: styles.buttonBaseSize,
    },
    rounded: {
      sm: styles.buttonRoundedSm,
    },
    fullWidth: {
      true: styles.buttonFullWidth,
    },
    centerContent: {
      true: styles.buttonCenterContent,
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'base',
  },
})

const Button = ({
  sendingMessageStatus,
  children,
  type = 'button',
  iconName,
  size,
  variant,
  successVariant,
  disabled = false,
  fullWidth,
  rounded,
  onClick = () => {},
  ...props
}) => {
  const [currentVariant, setCurrentVariant] = useState(variant)

  useEffect(() => {
    if (sendingMessageStatus === PROMISE_STATES.fulfilled) {
      setCurrentVariant(successVariant)

      // const timeout = setTimeout(() => {
      //   setCurrentVariant(variant)
      // }, 150000)

      // return () => clearTimeout(timeout)
    } else {
      setCurrentVariant(variant)
    }
  }, [sendingMessageStatus, successVariant, variant])

  const renderIcon = () => {
    if (iconName === 'google') {
      return <GoogleIcon className={styles.iconImage} />
    }

    if (iconName === 'apple') {
      return <AppleIcon className={styles.iconImage} />
    }
  }

  return (
    <button
      className={buttonStyles({ variant: currentVariant, size, fullWidth, rounded })}
      type={type}
      disabled={disabled}
      {...props}
      onClick={onClick}
    >
      {iconName && <span className={styles.icon}>{renderIcon()}</span>}
      {!sendingMessageStatus && children}
      {sendingMessageStatus === PROMISE_STATES.pending && (
        <Spinner className={styles.spinner} />
      )}
      {sendingMessageStatus === PROMISE_STATES.idle && children}
      {sendingMessageStatus === PROMISE_STATES.fulfilled && (
        <div className={styles.iconV2}>
          <CheckIcon />
        </div>
      )}
      {sendingMessageStatus === PROMISE_STATES.fulfilled && 'Summary Sent!'}
      {sendingMessageStatus === PROMISE_STATES.rejected && 'Error'}
    </button>
  )
}

export default Button
