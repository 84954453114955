import React, { useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { UiContext } from 'context/ui'

import LogoImg from 'i/icons/talkpro_logo_white.svg'
import styles from './DashboardHeader.module.scss'
import { ReactComponent as HamburgerIcon } from 'i/icons/hamburger_3_white.svg'
import { ReactComponent as SearchIcon } from 'i/icons/search_new.svg'

const DashboardHeader = ({ handleClick }) => {
  const location = useLocation()
  const { setActivePopupId } = useContext(UiContext)

  const openSearch = () => {
    setActivePopupId('search')
  }

  return (
    <div
      className={classNames(styles.header, {
        [styles.headerV2]: location.pathname === '/dashboard/pro',
      })}
    >
      <button className={styles.hamburger} type="button" onClick={handleClick}>
        <HamburgerIcon className="icon size_mod" />
      </button>
      {location.pathname === '/dashboard/pro' && (
        <button type="button" className={styles.headerSearch} onClick={openSearch}>
          <SearchIcon className={styles.headerSearchIcon} />
        </button>
      )}
      <Link className={styles.logo}>
        <img src={LogoImg} className={styles.logoImage} alt="Logo" />
      </Link>
    </div>
  )
}

export default DashboardHeader
